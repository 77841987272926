import { combineReducers } from '@reduxjs/toolkit';
import { contactApi } from '../../api/contactSlice';

const rootReducer = combineReducers({
    // Add the API slice reducer to the root reducer
    [contactApi.reducerPath]: contactApi.reducer,
    // Add other reducers here if you have them
});

export default rootReducer;
