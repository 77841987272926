import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const contactApi  = createApi({
  reducerPath: 'contactApi ',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://1361i91ead.execute-api.us-east-1.amazonaws.com/prod', 
  }),
  tagTypes: ['Post'],
  endpoints: (builder) => ({
    submitContact: builder.mutation({
      query: (payload) => ({
        url: '/contact-us',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['Post'],
    }),
  }),
})

export const { useSubmitContactMutation } = contactApi 