import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, TextField, Link } from "@mui/material";
import { green } from '@mui/material/colors';

import { useSubmitContactMutation } from "../../api/contactSlice";
import Joi from 'joi';

const contactSchema = Joi.object({
  firstName: Joi.string().min(3).max(30).required(),
  lastName: Joi.string().min(3).max(30).required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  phone: Joi.string()
    .required()
    .pattern(/^1\d{10}$|^\d{10}$/, 'numbers')
    .messages({
        'string.empty': `Phone number is required.`,
        'string.pattern.base': `Phone number must be 10 digits long and may start with '1'.`,
        'string.base': `Phone number must be a string of digits.`,
    }),
  street: Joi.string().required().min(3).max(100),
  city: Joi.string().required().min(3).max(100),
  message: Joi.string().required().min(3).max(500),
  agreeToTerms: Joi.boolean().invalid(false).messages({
    'any.invalid': 'Please confirm that you agree to our Privacy Policy!',
  }),
});


function ContactForm({ onSubmissionResponse  }) {
  const [submitContact, { isLoading, isError, isSuccess, error }] =
    useSubmitContactMutation();

  const submitSx = {
    ...(isSuccess && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  const initialFormData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    street: "",
    city: "",
    message: "",
    agreeToTerms: false,
  }

  // Local state to store form fields
  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
    // Clear checkbox error when user checks the field
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' && checked === true && undefined,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // validate errors
    const validationResult = contactSchema.validate(formData, { abortEarly: false });
    if (validationResult.error) {
      const errors = validationResult.error.details.reduce((acc, detail) => {
        acc[detail.path[0]] = detail.message; // Assumes error details have 'path' array
        return acc;
      }, {});
      setFormErrors(errors);
      return;
    } else {
      setFormErrors({}); // Clear errors if validation is successful
    }

    grecaptcha.enterprise.ready(async () => {
      const recaptchaKey = process.env.RECAPTCHA_API_KEY;
      const token = await grecaptcha.enterprise.execute(recaptchaKey, {action: 'SUBMIT'});
      // const payload = {
      //   ...formData,
      //   token,
      // };

      const { agreeToTerms, ...payload } = { ...formData, token };

      try {
        const response = await submitContact(payload).unwrap();
        // Handle response data
        setFormData(initialFormData);
        onSubmissionResponse(true);
      } catch (error) {
        // Handle error case
        console.error('Submission failed', error);
        const errorMsg = error?.data?.error || 'An error occurred submitting the form.';
        onSubmissionResponse(false, errorMsg);
      }
    });   
  };

  // useEffect(() => {
  //   console.log('isLoading:', isLoading);
  // }, [isLoading]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="firstName" 
            label="First Name"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name"
            variant="filled"
            error={!!formErrors.firstName}
            helperText={formErrors.firstName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="lastName" 
            label="Last Name"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name"
            variant="filled"
            error={!!formErrors.lastName}
            helperText={formErrors.lastName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            name="email" 
            label="Email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            variant="filled"
            error={!!formErrors.email}
            helperText={formErrors.email}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            name="phone" 
            label="Phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone"
            variant="filled"
            error={!!formErrors.phone}
            helperText={formErrors.phone}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            name="street" 
            label="Street Address"
            value={formData.street}
            onChange={handleChange}
            placeholder="Street Address"
            variant="filled"
            error={!!formErrors.street}
            helperText={formErrors.street}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            name="city" 
            label="City"
            value={formData.city}
            onChange={handleChange}
            placeholder="City"
            variant="filled"
            error={!!formErrors.city}
            helperText={formErrors.city}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            name="message" 
            label="Message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Your message"
            variant="filled"
            error={!!formErrors.message}
            helperText={formErrors.message}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                size="large"
                name="agreeToTerms"
                checked={formData.agreeToTerms}
                onChange={handleChange}
              />
            }
            label={
              <span style={{fontSize: '16px'}}>
                I have read and agree to the website's{' '}
                <Link href="/privacy-policy" target="_blank" color='primary' underline='always' rel="noopener noreferrer">
                  Privacy Policy
                </Link>
              </span>
            } 
          />
          <div style={{color: 'red'}}>{formErrors.agreeToTerms ? formErrors.agreeToTerms : ''}</div>
        </Grid>
      </Grid>
      <div className="action-block" style={{textAlign: '-webkit-right'}}>
        <Box sx={{ m: 1, position: 'relative', width: 'fit-content' }}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={submitSx}
            disabled={isLoading}
          >
            Submit
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                color: '#000E24',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </div>
    </form>
  );
}
ContactForm.propTypes = {
  onSubmissionResponse: PropTypes.func.isRequired,
};
export default ContactForm;
