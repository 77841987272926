import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from '../../containers/Home/Home';
import AboutUs from '../../containers/AboutUs/AboutUs';
import Battery from '../../containers/Battery/Battery';
import ContactUs from '../../containers/ContactUs/ContactUs';
import Gallery from '../../containers/Gallery/Gallery';
import NetMetering from '../../containers/NetMetering/NetMetering';
import PrivacyPolicy from '../../containers/Privacy Policy/PrivacyPolicy';

export default function CustomRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about-us" element={<AboutUs />} />
            <Route exact path="/battery" element={<Battery />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/gallery" element={<Gallery />} />
            <Route exact path="/net-metering" element={<NetMetering />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
    );
}
