/* eslint-disable import/no-unresolved */
import { useEffect, useState } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";

import store from "./services/redux/store";
import "./assets/scss/main.scss";
import "swiper/css";
import Layout from "./layout/Layout";
import Routes from "./services/routes/Routes";
import appTheme from "./constants/theme";
import ScrollToTop from "./components/ui/ScrollToTop/ScrollToTop";
import { initGA, logPageView } from "./analytics";

function PageViews() {
  const location = useLocation();
  useEffect(() => {
    logPageView();
  }, [location]);
  return null;
}

function App() {
  useEffect(() => {
    initGA("G-WP1418XEMJ");
  }, []);
  // you could toggle themes here using setTheme if you add an event and pass it down to the child toggle component
  const [theme, setTheme] = useState(appTheme);

  const muiTheme = createTheme(theme);

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Provider store={store}>
        <Router>
          <ScrollToTop />
          <PageViews />
          <Layout>
            <Routes />
          </Layout>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
