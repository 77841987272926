import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { contactApi } from '../../api/contactSlice';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        // Add the contactApi middleware to the default middleware array
        getDefaultMiddleware().concat(contactApi.middleware),
});

export default store;


// import { configureStore } from '@reduxjs/toolkit';
// import rootReducer from './rootReducer';

// const store = configureStore({
//     reducer: rootReducer,
// });

// export default store;
