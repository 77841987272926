import React, { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Grid,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import LOGO from "../../../assets/images/logo.png";
import IC_GOOGLE from "../../../assets/images/ic-google-logo.svg";
import IC_STAR from "../../../assets/images/ic-star.svg";
import IC_PHONE from "../../../assets/images/ic-cell.svg";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { withEmotionCache } from "@emotion/react";

const pages = [
  // { name: 'Home', path: '/', key: 'home' },
  { name: "Battery", path: "/battery", key: "battery" },
  { name: "Net Metering", path: "/net-metering", key: "net-metering" },
  { name: "About Us", path: "/about-us", key: "about-us" },
  { name: "Gallery", path: "/gallery", key: "gallery" },
  { name: "Contact Us", path: "/contact-us", key: "contact-us" },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const googleRating = 45;
const showMessagebar = false;

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  // const [rating, setRating] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuClick = (path) => {
    navigate(path);
    handleCloseNavMenu();
  };

  const isPrivacyPage = () => {
    if (location.pathname === "/privacy-policy") {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= 15);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

    // fetch(
    //     `https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJf4rWGFOMToYRKfNAYock6S8&fields=name,rating&key=AIzaSyAy-oJ7U9r1F89DRfi_DJ5QMuZNgH3DnOY`
    //   )
    //     .then((response) => response.json())
    //     .then((data) => {
    //       if (data.result && data.result.rating) {
    //         setRating(data.result.rating);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching Google Places API:', error);
    //     });
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        paddingRight: "12px",
        paddingLeft: "12px",
        backgroundColor:
          isScrolled || isPrivacyPage() ? "#000E24" : "transparent",
        boxShadow: "none",
        paddingTop: "12px",
        paddingBottom: "6px",
      }}
    >
      <Toolbar id="desktop-top" disableGutters>
        <Box
          sx={{
            minWidth: "409px",
            width: "100%",
            maxWidth: "475px",
            display: { xs: "none", lg: "flex" },
            justifyContent: "left",
            alignItems: "center",
            minHeight: "80px",
            height: "80px",
            maxHeight: "80px",
          }}
        >
          <NavLink
            to="/"
            className="logo"
            title="Brand"
            sx={{ display: { xs: "none", lg: "flex" }, mr: 1 }}
          >
            <img
              src={LOGO}
              alt="logo"
              style={{ width: "52px", height: "46px" }}
            />
          </NavLink>
          <Typography
            variant="h6"
            noWrap
            component={NavLink}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", lg: "flex" },
              fontFamily: "Nunito, sans-serif",
              fontSize: "28px",
              fontWeight: 400,
              letterSpacing: "0.6px",
              color: "inherit",
              textDecoration: "none",
              paddingLeft: "12px",
            }}
          >
            Texas Solar Professional
          </Typography>
        </Box>
        <Box
          id="hamburger"
          sx={{
            flexGrow: 1,
            display: { xs: "flex", lg: "none" },
            paddingLeft: "12px",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            aria-label="open drawer"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon sx={{ minWidth: "24px", minHeight: "24px" }} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", lg: "none" },
            }}
          >
            {pages.map((page) => (
              <MenuItem
                key={page.key}
                onClick={() => handleMenuClick(page.path)}
              >
                <Typography textAlign="center" variant="h5">
                  {page.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        {/* <!-- MOBILE DISPLAYED LOGO, COMPANY NAME --> */}
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", lg: "none" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NavLink
            to="/"
            className="logo"
            title="Brand"
            sx={{ display: { xs: "flex", lg: "none" }, mr: 1 }}
          >
            <img
              src={LOGO}
              alt="logo"
              style={{ width: "42px", height: "36px" }}
            />
          </NavLink>
          {/* Top Bar */}
          <Typography
            variant="h5"
            noWrap
            component={NavLink}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "flex", lg: "none" },
              flexGrow: 1,
              fontFamily: "Nunito, sans-serif",
              fontSize: "20px",
              fontWeight: 400,
              letterSpacing: "0.6px",
              color: "inherit",
              textDecoration: "none",
              paddingLeft: "12px",
            }}
          >
            Texas Solar Professional
          </Typography>
        </Box>
        <Box
          id="box-menu-test"
          sx={{
            flexGrow: 1,
            display: { xs: "none", lg: "flex" },
            justifyContent: "center",
          }}
        >
          <div
            id="menu-wrapper"
            style={{
              display: "flex",
              minWidth: "668px",
              width: "668px",
              maxWidth: "668px",
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.key}
                onClick={() => handleMenuClick(page.path)}
                sx={{ my: 2, color: 'white', display: 'block', padding: '8px 21px', letterSpacing: '0.6px', lineHeight: '24px' }}
              >
                {page.name}
              </Button>
              // <NavLink
              //   key={page.key}
              //   to={page.path}
              //   // onClick={() => handleMenuClick(page.path)}
              //   style={{
              //     my: 2,
              //     color: "white",
              //     display: "block",
              //     padding: "8px 21px",
              //     letterSpacing: "0.6px",
              //     lineHeight: "24px",
              //     fontWeight: "bold"
              //   }}
              // >{page.name}</NavLink>
            ))}
          </div>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "flex",
            },
            justifyContent: "right",
            alignItems: "center",
            minWidth: "167px",
            width: "100%",
            maxWidth: "332px",
            minHeight: "80px",
            height: "80px",
            maxHeight: "80px",
          }}
        >
          <Button
            variant="contained"
            color="warning"
            component={NavLink}
            to="/contact-us"
            sx={{ margin: "6px 16px" }}
          >
            Get a Quote
          </Button>
        </Box>
      </Toolbar>

      {/* Bottom menu for phone */}
      <Toolbar
        disableGutters
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "none",
            xl: "flex",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            width: "100%",
          }}
        >
          <NavLink to="tel:+1 (469) 581-0008">
            <em style={{ verticalAlign: "top" }}>
              <img src={IC_PHONE} alt="cell" />
            </em>
            <span
              style={{
                fontFamily: "Nunito, sans-serif",
                padding: "0 16px 0 8px",
                fontSize: "16px",
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
                color: "white",
              }}
            >
              +1 (469) 581-0008
            </span>
          </NavLink>
        </Box>
      </Toolbar>

      {/* Bottom Menu for Mobile */}
      <Toolbar disableGutters sx={{ display: { lg: "flex", xl: "none" } }}>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          {/* Phone Section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 1,
              minWidth: "240px",
              maxWidth: "240px",
            }}
          >
            <NavLink to="tel:+1 (469) 581-0008">
              <em style={{ verticalAlign: "top" }}>
                <img
                  src={IC_PHONE}
                  alt="cell"
                  style={{ verticalAlign: "middle" }}
                />
              </em>
              <span
                style={{
                  fontFamily: "Nunito, sans-serif",
                  padding: "0 16px 0 8px",
                  fontSize: "16px",
                  fontWeight: 700,
                  color: "inherit",
                  textDecoration: "none",
                  color: "white",
                  verticalAlign: "bottom",
                }}
              >
                +1 (469) 581-0008
              </span>
            </NavLink>
          </Box>
          {/* Button Section */}
          <Box
            sx={{
              maxWidth: "912px",
              display: "flex",
              justifyContent: "right",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="warning"
              component={NavLink}
              to="/contact-us"
              sx={{ width: "100%", maxWidth: "213px", fontSize: "14px" }}
            >
              Get a Quote
            </Button>
          </Box>
        </Box>
      </Toolbar>
      {/* <Toolbar disableGutters sx={{ display: { xs: 'flex', lg: 'none' }}}>       
          <Box id='box-test' sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' }, justifyContent: 'center', border: '1px solid yellow' }}>
            <PhoneInTalkIcon sx={{paddingRight: '2px'}} />
            <Link to="tel:+1 (469) 581-0008" href="#" underline="none" sx={{color: 'white', paddingLeft: '4px' }}>
                <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        sx={{
                        flexGrow: 1,         
                        fontFamily: 'Nunito, sans-serif',
                        fontSize: '16px',
                        fontWeight: 700,
                        color: 'inherit',
                        textDecoration: 'none',
                        }}
                    >
                        +1 (469) 581-0008      
                </Typography>
            </Link>
            <Button
                variant="contained"
                color="warning"
                component={NavLink}
                to="/contact-us"
                sx={{}}
            >
                Get a Quote
            </Button>
        </Box>
      </Toolbar> */}
    </AppBar>
  );
}
export default Header;
