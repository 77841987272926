import React from "react";

import HERO_BANNER_IMG from "../../assets/images/latest/BATTERY.jpg";
import { Helmet } from "react-helmet-async";

const privacyPolicySections = [
  {
    index: 1,
    title: "Your Privacy is Our Priority",
    description:
      "It is Texas Solar Professional LLC’s policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to texassolar.pro (hereinafter, “us”, “we”, or “texassolar.pro”). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy (“Privacy Policy”) to explain what information may be collected on our website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources. This Privacy Policy sets forth the general rules and policies governing your use of our website. Depending on your activities when visiting our website, you may be required to agree to additional terms and conditions.",
  },
  {
    index: 2,
    title: "Website Visitors",
    description:
      "Like most website operators, Texas Solar Professional LLC collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Texas Solar Professional LLC’s purpose in collecting non-personally identifying information is to better understand how Texas Solar Professional LLC’s visitors use its website. From time to time, Texas Solar Professional LLC may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website. Texas Solar Professional LLC also collects potentially personally-identifying information like Internet Protocol (IP) addresses from its users.",
  },
  {
    index: 3,
    title: "Gathering of Personally-Identifying Information",
    description:
      "Certain visitors to Texas Solar Professional LLC’s websites choose to interact with Texas Solar Professional LLC in ways that require Texas Solar Professional LLC to gather personally-identifying information. The amount and type of information that Texas Solar Professional LLC gathers depends on the nature of the interaction. For example, we ask visitors who request a service at texassolar.pro to provide a name, phone number, email address, street address and city.",
  },
  {
    index: 4,
    title: "Security",
    description:
      "The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.",
  },
  {
    index: 5,
    title: "Links to External Sites",
    description:
      "Our Service may contain links to external sites that are not operated by us. If you click on a third-party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit. We have no control over, and assume no responsibility for the content, privacy policies or practices of any third-party sites, products or services.",
  },
  {
    index: 6,
    title: "Protection of Certain Personally-Identifying Information",
    description:
      "Texas Solar Professional LLC discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Texas Solar Professional LLC’s behalf or to provide services available at Texas Solar Professional LLC’s website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Texas Solar Professional LLC’s website, you consent to the transfer of such information to them. Texas Solar Professional LLC will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Texas Solar Professional LLC discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Texas Solar Professional LLC believes in good faith that disclosure is reasonably necessary to protect the property or rights of Texas Solar Professional LLC, third parties or the public at large. If you have requested a service via texassolar.pro and have supplied your email address or phone number, Texas Solar Professional LLC may contact you to discuss products or services. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Texas Solar Professional LLC takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.",
  },
  {
    index: 7,
    title: "Aggregated Statistics",
    description:
      "Texas Solar Professional LLC may collect statistics about the behavior of visitors to its website. Texas Solar Professional LLC may display this information publicly or provide it to others. However, Texas Solar Professional LLC does not disclose your personally-identifying information.",
  },
];

const PrivacySection = ({ section }) => {
  return (
    <div className="card img-content-card mt-2">
      <div>
        <h5>{section.title}</h5>
        <p> {section.description}</p>
      </div>
    </div>
  );
};

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Texas Solar Professional | Privacy Policy</title>
        <meta
          name="description"
          content="Texas Solar Professional Privacy Policy Page"
        />
        <link rel="canonical" href="https://texassolar.pro/privacy-policy" />
      </Helmet>
      <div className="privacy-page">
        {/* <section
        className="hero-section secondary"
        style={{ backgroundImage: `url(${HERO_BANNER_IMG})` }}
      >
        <h3>Privacy Policy</h3>
      </section> */}
        <section className="privacy-system-section">
          <div className="container">
            {privacyPolicySections.map((section) => (
              <PrivacySection section={section} key={section.index} />
            ))}
            <div className="card img-content-card mt-2">
              <div>
                <h5>Privacy Policy Changes</h5>
                <p>
                  <b>Opting In to Receive Promotional Text Messages: </b>
                  By providing your mobile phone number and opting in to receive
                  promotional text messages from Texas Solar Professional LLC,
                  you consent to receive text messages, including marketing
                  messages, at the phone number provided. These messages may
                  include offers, promotions, updates, and other information
                  related to our products and services.
                </p>
                <p className="mt-2">
                  <b>How to Opt In: </b>
                  To opt in to receive promotional text messages, you can
                  provide your mobile phone number through our website, mobile
                  application, or by other means where the option is presented.
                </p>
                <p className="mt-2">
                  <b>Frequency of Messages: </b>
                  The frequency of text messages you receive may vary depending
                  on the promotions and updates we have to share. We strive to
                  keep the number of messages reasonable.
                </p>
                <p className="mt-2">
                  <b>Opting Out: </b>
                  You can opt out of receiving promotional text messages at any
                  time by replying to any message with the word “STOP.” You may
                  also opt out by contacting us directly through the contact
                  information provided below. Once you opt out, you will no
                  longer receive promotional text messages from Texas Solar
                  Professional LLC, although you may still receive transactional
                  or service-related messages as permitted by law.
                </p>
                <p className="mt-2">
                  <b>Data and Message Rates: </b>
                  Message and data rates may apply to receiving text messages.
                  Your mobile carrier’s standard messaging and data rates may
                  apply. Please consult your mobile service carrier for details.
                </p>
                <p className="mt-2">
                  <b>Privacy: </b>
                  We value your privacy and will only use your mobile phone
                  number for the purpose of sending you promotional text
                  messages in accordance with this policy. We will not share
                  your mobile phone number with third parties for their
                  marketing purposes without your consent.
                </p>
              </div>
            </div>

            <p className="mt-2 pl-1">
              If you have any questions about this Privacy Policy, please
              contact us via email or phone.
            </p>
          </div>
        </section>
      </div>
    </>
  );
}

export default PrivacyPolicy;
