import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const stepsInfoPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired, // Assuming icon is a string, update accordingly
  })
);

function Timeline(props) {
  const { itemsMobile, itemsDesktop } = props;
  const [stepsInfo, setStepsInfo] = useState(itemsDesktop);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        // Define your mobile breakpoint here
        setStepsInfo(itemsMobile);
      } else {
        setStepsInfo(itemsDesktop);
      }
    };

    // Initial call to set items based on current window size
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, [itemsDesktop, itemsMobile]);

  return (
    <>
      <section className="timeline-wrapper">
        <div className="container">
          <h3>Our Process</h3>
          <div className="timeline-outer-box">
            {stepsInfo.map((item, idx) => {
              return (
                <div className="timeline-inner" key={item.label + idx}>
                  <div className="timeline-inner-main">
                    <em className="timeline-icon">
                      <img src={item.icon} alt="signature" />
                    </em>
                    <div className="timeline-content">
                      <h5>{item.label}</h5>
                      {idx === 7 || idx === 8 ? (
                        <p style={{ height: "100px" }}>{item.key}</p>
                      ) : (
                        <p>{item.key}</p>
                      )}
                      {/* <HtmlTooltip
                                      className="warranties-tooltip"
                                      enterTouchDelay={0}
                                      title={<p>{content}</p>}
                                  >
                                      <p>{content}</p>
                                  </HtmlTooltip> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
Timeline.propTypes = {
  itemsMobile: stepsInfoPropTypes.isRequired,
  itemsDesktop: stepsInfoPropTypes.isRequired,
};
export default Timeline;
