/* eslint-disable import/no-unresolved */
import React from "react";
import { Helmet } from "react-helmet-async";
import { Grid } from "@mui/material";
import HERO_BANNER_IMG from "../../assets/images/latest/NetMeteringHeader.png";
import THUMB_IMAGE6 from "../../assets/images/net-metering-img.jpg";

function NetMetering(props) {
  return (
    <>
      <Helmet>
        <title>
          Net Metering: Texas has great programs to send excess power and get
          credit back for it from the grid
        </title>
        <meta name="description" content="Texas net metering is a very essential part of doing solar for most clients. It allows clients to sell back excess power back into the electric if not used in their home or business. It helps increase the value of the solar and the savings of the person doing the solar." />
        <meta
          name="keywords"
          content="Texas net metering, Texas net billing, Oncor net metering, Deregulated net metering, Deregulated net billing, Solar net metering, Solar net billing"
        />
        <link rel="canonical" href="https://texassolar.pro/net-metering" />
      </Helmet>
      <div className="net-metering-page">
        <section
          className="hero-section secondary"
          style={{ backgroundImage: `url(${HERO_BANNER_IMG})` }}
        >
          <h3>Net Metering</h3>
        </section>
        <section className="battery-description-section bg-dark">
          <div className="container">
              <p>
                Texas net metering is a very essential part of doing solar for
                most clients. It allows clients to sell back excess power back
                into the electric if not used in their home or business. It
                helps increase the value of the solar and the savings of the
                person doing the solar.
              </p>
          </div>
        </section>
        <section className="net-metering-info">
          <div className="container">
            <Grid container spacing={{ xs: 2, md: 4, lg: 5 }}>
              <Grid item xs={12} lg={6} className="tc">
                <img src={THUMB_IMAGE6} alt="" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <p>
                  When considering a solar system that works only during the day
                  net metering is a very large factor in determining how much
                  savings will happen the solar system. In Texas consumption is
                  read on 15 minute intervals. Depending on if you have a
                  deregulated or regulated utility will determine how they buy
                  and account for your solar electricity production. Some will
                  store the credit for you and allow you to use at night or when
                  you need more power than what the solar is providing during
                  the day. Some will buy your power on 15 minute intervals at a
                  predetermined price. Others will buy at current market value.
                </p>
                <p>
                  Always Always Always is important for our clients to know how
                  their net metering will work for them. From there the
                  determination of what size system or a battery can properly be
                  taken to meeting clients needs and expectations. We understand
                  solar and clients understand their electric bill. We hear the
                  horror stories about solar a lot.... It usually is in delays
                  and not being told the truth about how the utility company
                  would interact with the solar production overages that were
                  not consumed in the home. Our objective remains to be your
                  trusted source of accurate information.
                </p>
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
    </>
  );
}

export default NetMetering;
