import { createTheme } from '@mui/material/styles';

// background: #ECE9E6;  /* fallback for old browsers */
// background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */
// background: linear-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

const appTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    typography: {
        fontFamily: "'Nunito', sans-serif",
        h3: {
            fontSize: '4.6rem',
            fontWeight: 700,
            lineHeight: '5.8rem',
            letterSpacing: '0.05rem',
            color: '#fff',
        },
        h4: {
            fontSize: '3.2rem',
            fontWeight: 700,
            lineHeight: '4.4rem',
            letterSpacing: '0.05rem',
            color: '#00235A',
        },
        h5: {
            fontSize: '2.4rem',
            fontWeight: 700,
            lineHeight: '3.4rem',
            letterSpacing: '0.05rem',
            color: '#000712',
        },
    },
    palette: {
        primary: {
            main: '#00235A',
            contrastText: '#fff',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '.5rem',
                    fontSize: '1.6rem',
                    fontWeight: 700,
                    letterSpacing: '0.06rem',
                    boxShadow: 'none',
                    textTransform: 'capitalize',
                    transition: 'all 0.3s',
                    minHeight: '4.4rem',
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    height: '5.6rem',
                    borderRadius: '.5rem',
                    fontSize: '1.6rem',
                    lineHeight: '2rem',
                    letterSpacing: '0.02rem',
                    '&::after, &::before': {
                        display: 'none',
                    },
                },
                input: {
                    transition: 'all 0.3s',
                    height: '5.6rem',
                    padding: '2.4rem 2.4rem 0.7rem',
                    borderRadius: '.5rem',
                    backgroundColor: '#fff',
                    border: '1px solid #C1CDD7',
                    color: '#000712',
                    '&:focus': {
                        backgroundColor: '#fff',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                filled: {
                    transition: 'all 0.3s',
                    fontSize: '1.6rem',
                    lineHeight: '2rem',
                    letterSpacing: '0.02rem',
                    color: '#5E6268',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                },
                select: {
                    minHeight: '5.6rem',
                    '&:focus': {
                        borderRadius: '.5rem',
                    },
                },
                icon: {
                    fontSize: '3.5rem',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '1.6rem',
                    lineHeight: '2rem',
                    letterSpacing: '0.02rem',
                    color: '#000712',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip : {
                    fontSize:'1.4rem',
                    lineHeight: '1.8rem',
                    backgroundColor: '#082249',
                    borderRadius: '0.6rem',
                    padding: '1rem',
                },
                arrow: {
                    color: '#082249',
                },
            }
        }
    },
});
export default appTheme;
