import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Alert,
  Grid,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Helmet } from "react-helmet-async";

import HERO_BANNER_IMG from "../../assets/images/hero-banner-img.jpg";
import ContactForm from "../../components/ContactForm/ContactForm";
import CONTACTUS_THUMB from "../../assets/images/contactus-thumb.png";

function ContactUs() {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success");
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const handleFormResponse = (success, errorMsg = "") => {
    setDialogMessage(
      success ? "Success! We've received your message." : `Error: ${errorMsg}`
    );
    setDialogSeverity(success ? "success" : "error");
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Helmet>
        <title>
          Contact Us: Texas local solar installer with great customer service
        </title>
        <meta
          name="description"
          content="We are dedicated to servicing our clients"
        />
        <meta
          name="keywords"
          content="Solar customer service, Solar hail, Solar panels hail, Solar panels reroof, Texas hail solar panels"
        />
        <link rel="canonical" href="https://texassolar.pro/contact-us" />
      </Helmet>
      <div className="contactus-page">
        <section
          className="hero-section secondary"
          style={{ backgroundImage: `url(${HERO_BANNER_IMG})` }}
        >
          <h3>Contact us</h3>
        </section>

        <section className="battery-description-section bg-dark">
          <div className="container">
            <p>We are dedicated to servicing our clients</p>
          </div>
        </section>

        <section className="contactus-info">
          <div className="container">
            <Grid container spacing={{ xs: 2, md: 4, lg: 5 }}>
              {!isSmallScreen && (
                <Grid item xs={12} lg={6}>
                  <div className="img-card">
                    <img src={CONTACTUS_THUMB} alt="Contact Us" />
                  </div>
                </Grid>
              )}

              <Grid item xs={12} lg={6}>
                <div className="card">
                  <ContactForm onSubmissionResponse={handleFormResponse} />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            <Alert
              sx={{ fontSize: "1.875rem", backgroundColor: "white" }}
              severity={dialogSeverity}
            >
              {dialogMessage}
            </Alert>
          </DialogTitle>
          <IconButton
            sx={{
              position: "absolute",
              right: 4,
              top: 4,
            }}
            aria-label="close"
            color={dialogSeverity}
            onClick={handleCloseDialog}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Dialog>
      </div>
    </>
  );
}

export default ContactUs;
