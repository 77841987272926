import React from "react";

import { Grid } from "@mui/material";
import { locationWhite, phone } from "../../../assets/images";
import { NavLink, useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const navigateToPrivacyPolicy = () => {
    navigate("/privacy-policy");
  };

  const locations = [
    {
      name: "Dallas",
      address: "545 Jealouse Way, Cedar Hill, Texas 75104",
      phone: "+1 (469) 581-0008",
    },
    {
      name: "Houston",
      address: "11011 Brooklet Dr Houston, Texas 77099",
      phone: "+1 (832) 377-7913",
    },
  ];

  return (
    <section className="footer-wrapper location-wrapper">
      <div className="container">
        <h3>Our Locations</h3>
        <Grid container spacing={3}>
          {locations.map((location, index) => (
            <Grid item xs={12} sm={12} md={6} key={index}>
              <div className="location-box">
                <em className="location-icon">
                  <img src={locationWhite} alt="location" />
                </em>
                <div className="location-info">
                  <h3>{location.name}</h3>
                  <p>{location.address}</p>
                  <NavLink to={`tel: ${location.phone}`} className="footer-links">
                    <img
                      src={phone}
                      alt="cell"
                      style={{ verticalAlign: "middle" }}
                    />{" "}
                    {location.phone}
                  </NavLink>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
        <div className="copyright-section">
          <center>
            <span onClick={navigateToPrivacyPolicy} className="footer-links">
              PRIVACY POLICY
            </span>
          </center>
        </div>
      </div>
    </section>
  );
}

export default Footer;
