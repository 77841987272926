import {
    signatureWhite,
    assignment,
    thumb,
    build,
    lockOpenWhite,
    downloadArrow,
    searchWhite,
    monitor,
    headset,
} from '../assets/images';

const cities = [
    {
        name: 'Houston',
        coordinates: { latitude: 29.7604, longitude: -95.3698 },
    },
    { name: 'Austin', coordinates: { latitude: 30.25, longitude: -97.75 } },
    { name: 'Dallas', coordinates: { latitude: 32.7767, longitude: -96.797 } },
    {
        name: 'San Antonio',
        coordinates: { latitude: 29.4241, longitude: -98.4936 },
    },
    {
        name: 'El Paso',
        coordinates: { latitude: 31.7619, longitude: -106.485 },
    },
];

const signContract = {
    label: 'Sign Contract',
    text: 'When you are ready you can sign contract. This will move you forward to projects and we then schedule a site survey with you.',
    icon: signatureWhite,
};
const signSurvey = {
    label: 'Site Survey',
    text: 'We send our site survey specialist to prep for your project completion. He or she gathers information for us to engineer your project.',
    icon: assignment,
};
const approveLayout = {
    label: 'Approve Layout',
    text: 'Before engineering and permitting we approve the layout with you so we are all on the same page.',
    icon: thumb,
};
const installation = {
    label: 'Installation',
    text: 'Professionally installed. Depending on your project this could take 1-5 days.',
    icon: downloadArrow,
};
const permitting = {
    label: 'Permitting',
    text: 'We get the approvals for HOA, Building Jurisdiction, and Utility Company. This is included in our pricing.',
    icon: lockOpenWhite,
};
const engineering = {
    label: 'Engineering',
    text: 'We make the plansets to get approvals for your project.',
    icon: build,
};
const inspection = {
    label: 'Inspection',
    text: 'We work to have your project fully inspected within two weeks of installation.',
    icon: searchWhite,
};
const monitoring = {
    label: 'Monitoring',
    text: 'You will have a monitoring application to see how the system is producing. You, us, and manufacturing will all be able to monitor the production of the system.',
    icon: monitor,
};
const customerService = {
    label: 'Continued Customer Service',
    text: 'It is our goal to proactively speak approximately every six months. We looking forward to serving you. We hope to earn referrals from you! We pay 500 dollars per referral you give to us. Thank you!',
    icon: headset,
};
const desktopStepsInfo = [
    {
        label: signContract.label,
        key: signContract.text,
        icon: signContract.icon,
    },
    { label: signSurvey.label, key: signSurvey.text, icon: signSurvey.icon },
    {
        label: approveLayout.label,
        key: approveLayout.text,
        icon: approveLayout.icon,
    },
    {
        label: installation.label,
        key: installation.text,
        icon: installation.icon,
    },
    { label: permitting.label, key: permitting.text, icon: permitting.icon },
    { label: engineering.label, key: engineering.text, icon: engineering.icon },
    { label: inspection.label, key: inspection.text, icon: inspection.icon },
    { label: monitoring.label, key: monitoring.text, icon: monitoring.icon },
    {
        label: customerService.label,
        key: customerService.text,
        icon: customerService.icon,
    },
];
const mobileStepsInfo = [
    {
        label: signContract.label,
        key: signContract.text,
        icon: signContract.icon,
    },
    { label: signSurvey.label, key: signSurvey.text, icon: signSurvey.icon },
    {
        label: approveLayout.label,
        key: approveLayout.text,
        icon: approveLayout.icon,
    },
    { label: engineering.label, key: engineering.text, icon: engineering.icon },
    { label: permitting.label, key: permitting.text, icon: permitting.icon },
    {
        label: installation.label,
        key: installation.text,
        icon: installation.icon,
    },
    { label: inspection.label, key: inspection.text, icon: inspection.icon },
    { label: monitoring.label, key: monitoring.text, icon: monitoring.icon },
    {
        label: customerService.label,
        key: customerService.text,
        icon: customerService.icon,
    },
];

const constants = { cities, desktopStepsInfo, mobileStepsInfo };

export default constants;
