import React from "react";
import { Helmet } from "react-helmet-async";

// import HERO_RESIDENTIAL_IMG from '../../assets/images/latest/home-page-residential-original-1.jpg';
// import HERO_COMMERCIAL_IMG from '../../assets/images/latest/home-page-commercial.jpg';
import HERO_RESIDENTIAL_IMG from "../../assets/images/hero-residential-img.jpg";
import HERO_COMMERCIAL_IMG from "../../assets/images/hero-commercial-img.jpg";
import TESLA_LOGO from "../../assets/images/tesla-logo.png";
import GOOGLE_RATING_LOGO from "../../assets/images/google-rating-logo.png";
import NABCEP_LOGO from "../../assets/images/nabcep-logo.png";
import TIER_GROUPS_LOGO from "../../assets/images/TIER-GROUPS-logo.png";
import ES_ELITE_LOGO from "../../assets/images/ES-elite-logo.png";
import THUMB_IMAGE from "../../assets/images/latest/CustomerService1.png";
import Timeline from "../../components/ui/Timeline/Timeline";

import homeData from "../../data/home";

import constants from "../../constants/constants";

// import MediaQueryDebugger from "../../components/tools/Debugging/MediaQueryDebugger";

function Home() {
  return (
    <>
      <Helmet>
        <title>Texas Solar Professional: Local Solar Company that does the job right</title>
        <meta name="description" content="We are a local Texas solar company that works to serve Texas the best we can. We keep our costs down so you get the best product installed for the best value. We don't waste our money on marketing and commissions. We focus on product and process for our clients. With us, you won't get a slick sales pitch. You will get honest and direct information. No pressure to purchase. You let us know when we can do the job for you. A local Texas solar installation done right."/>
        <meta name="keywords" content="Local solar company, Local Texas Solar Company, Local Texas Solar Installation, Texas Solar Professional, Texas Solar, Tex Solar" />
        <link rel="canonical" href="https://texassolar.pro/" />
      </Helmet>
      <div className="home-page">
        {/* Hero */}
        <section className="hero-section secondary d-alert-pt">
          <div className="hero-img-wrapper">
            {/* <div className="hero-wrapper-left">
            <div
              className="hero-img-left"
              style={{
                backgroundImage: `url(${HERO_RESIDENTIAL_IMG})`,
                backgroundSize: "87%",
              }}
            />
          </div>
          <div className="hero-wrapper-right">
            <div
              className="hero-img-right"
              style={{
                backgroundImage: `url(${HERO_COMMERCIAL_IMG})`,
                backgroundSize: "92%",
                backgroundPositionY: "66%",
                marginLeft: "-14%",
              }}
            />
          </div> */}
            <div className="hero-wrapper">
              <div
                className="hero-img"
                style={{
                  backgroundImage: `url(${HERO_RESIDENTIAL_IMG})`,
                }}
              />
            </div>
            <div className="hero-wrapper">
              <div
                className="hero-img"
                style={{
                  backgroundImage: `url(${HERO_COMMERCIAL_IMG})`,
                }}
              />
            </div>
          </div>

          <div className="hero-info-wrapper">
            <div
              className="hero_info"
              style={{
                backgroundImage: `url(${HERO_RESIDENTIAL_IMG})`,
              }}
            >
              <h3>{homeData.residential.title}</h3>
              {/* <p>{homeData.residential.text}</p> */}
              {/* <Button
                            variant="outlined"
                            className="white blur"
                            endIcon={
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.3172 10.4425L11.6922 16.0675C11.5749 16.1848 11.4159 16.2507 11.25 16.2507C11.0841 16.2507 10.9251 16.1848 10.8078 16.0675C10.6905 15.9503 10.6247 15.7912 10.6247 15.6253C10.6247 15.4595 10.6905 15.3004 10.8078 15.1832L15.3664 10.6253H3.125C2.95924 10.6253 2.80027 10.5595 2.68306 10.4423C2.56585 10.3251 2.5 10.1661 2.5 10.0003C2.5 9.83459 2.56585 9.67562 2.68306 9.55841C2.80027 9.4412 2.95924 9.37535 3.125 9.37535H15.3664L10.8078 4.81753C10.6905 4.70026 10.6247 4.5412 10.6247 4.37535C10.6247 4.2095 10.6905 4.05044 10.8078 3.93316C10.9251 3.81588 11.0841 3.75 11.25 3.75C11.4159 3.75 11.5749 3.81588 11.6922 3.93316L17.3172 9.55816C17.3753 9.61621 17.4214 9.68514 17.4529 9.76101C17.4843 9.83688 17.5005 9.91821 17.5005 10.0003C17.5005 10.0825 17.4843 10.1638 17.4529 10.2397C17.4214 10.3156 17.3753 10.3845 17.3172 10.4425Z"
                                        fill="white"
                                    />
                                </svg>
                            }
                        >
                            {homeData.residential.more}
                        </Button> */}
            </div>
            <div
              className="hero_info"
              style={{
                backgroundImage: `url(${HERO_COMMERCIAL_IMG})`,
              }}
            >
              <h3>{homeData.commercial.title}</h3>
              {/* <p>{homeData.commercial.text}</p> */}
              {/* <Button
                            variant="outlined"
                            className="white blur"
                            endIcon={
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17.3172 10.4425L11.6922 16.0675C11.5749 16.1848 11.4159 16.2507 11.25 16.2507C11.0841 16.2507 10.9251 16.1848 10.8078 16.0675C10.6905 15.9503 10.6247 15.7912 10.6247 15.6253C10.6247 15.4595 10.6905 15.3004 10.8078 15.1832L15.3664 10.6253H3.125C2.95924 10.6253 2.80027 10.5595 2.68306 10.4423C2.56585 10.3251 2.5 10.1661 2.5 10.0003C2.5 9.83459 2.56585 9.67562 2.68306 9.55841C2.80027 9.4412 2.95924 9.37535 3.125 9.37535H15.3664L10.8078 4.81753C10.6905 4.70026 10.6247 4.5412 10.6247 4.37535C10.6247 4.2095 10.6905 4.05044 10.8078 3.93316C10.9251 3.81588 11.0841 3.75 11.25 3.75C11.4159 3.75 11.5749 3.81588 11.6922 3.93316L17.3172 9.55816C17.3753 9.61621 17.4214 9.68514 17.4529 9.76101C17.4843 9.83688 17.5005 9.91821 17.5005 10.0003C17.5005 10.0825 17.4843 10.1638 17.4529 10.2397C17.4214 10.3156 17.3753 10.3845 17.3172 10.4425Z"
                                        fill="white"
                                    />
                                </svg>
                            }
                        >
                            {homeData.commercial.more}
                        </Button> */}
          </div>
        </div>
      </section>
      {/* <MediaQueryDebugger /> */}
      {/* custom-service */}
      <section className="our-values-section bg-dark">
        <div className="container">
            <div>
              <h3>{homeData.whyChooseUs.header}</h3>
              <p>{homeData.whyChooseUs.paragraph1}</p>
              <p className="m-0">{homeData.whyChooseUs.paragraph2}</p>
          </div>
        </div>
      </section>
      {/* Timeline-section */}
      <Timeline
        itemsDesktop={constants.desktopStepsInfo}
        itemsMobile={constants.mobileStepsInfo}
      />

        {/* partner-section */}
        <section className="partner-section">
          <div className="container">
            <ul className="partner-block">
              <li>
                <img src={TESLA_LOGO} alt="" />
              </li>
              <li>
                <img src={GOOGLE_RATING_LOGO} alt="" />
              </li>
              <li>
                <img src={NABCEP_LOGO} alt="" />
              </li>
              <li>
                <img src={TIER_GROUPS_LOGO} alt="" />
              </li>
              <li>
                <img src={ES_ELITE_LOGO} alt="" />
              </li>
            </ul>
          </div>
        </section>

        {/* custom-service */}
        <section className="custom-service-section bg-dark">
          <div className="container">
            <div className="_left">
              <img src={THUMB_IMAGE} alt="" />
            </div>
            <div className="_right">
              <div>
                <h3>{homeData.customerService.header}</h3>
                <p>{homeData.customerService.paragraph1}</p>
                <p className="m-0">{homeData.customerService.paragraph2}</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
