/* eslint-disable import/no-unresolved */
import React from "react";
import { Helmet } from "react-helmet-async";
import ImageGallery from "../../components/ui/ImageGallery/image-gallery";
import HERO_BANNER_IMG from "../../assets/images/hero-banner-img.jpg";

function Gallery(props) {
  return (
    <>
      <Helmet>
        <title>Gallery: Pictures of solar installations done right</title>
        <meta
          name="description"
          content="Installations of solar panels in the great state of Texas. We use beautiful solar panels and do very clean work."
        />
        <meta name="keywords" content="Solar Installation, solar conduit attic runs, black on black panels, Local Texas Solar install pics, Texas Solar install pictures" />
        <link rel="canonical" href="https://texassolar.pro/gallery" />
      </Helmet>
      <div className="gallery-page">
        <section
          className="hero-section secondary"
          style={{ backgroundImage: `url(${HERO_BANNER_IMG})` }}
        >
          <h3>Gallery</h3>
        </section>

        <section className="battery-description-section bg-dark">
          <div className="container">
            <p>
              Installations of solar panels in the great state of Texas. We use
              beautiful solar panels and do very clean work.
            </p>
          </div>
        </section>

        <section className="image-grid-section">
          <div className="container">
            <h3>
              We take pride in installing solar in Texas.
              <span>Yes it is beautiful…. We agree!</span>
            </h3>
            <ImageGallery />
          </div>
        </section>
      </div>
    </>
  );
}

export default Gallery;
