const homeData = {
  residential: {
    title: "Residential",
    text: "Solar Solution",
    more: "Explore More",
  },
  commercial: {
    title: "Commercial",
    text: "Solar Solution",
    more: "Explore More",
  },
  process: "Our Process",
  customerService: {
    header: "Customer Service",
    paragraph1:
      "Our commitment to you is that we will follow up every six months after the installation or however much you would like us to. We want to check in with you on the production of the system, How it is affecting your electrical bill, and any questions or concerns you might have.",
    paragraph2:
      "Of course referrals are always welcome. We pay you 500 dollars for people who you refer who go solar with us. The more you refer the better we will do as a company and the better for your warranty. As a company we understand this. We work from advertising to sale, install to customer service to not only provide proper expectations, but then to complete and even exceed those expectations.",
  },
  whyChooseUs: {
    header: "Why Choose Us?",
    paragraph1:
      "We are a local Texas solar company that works to serve Texas the best we can. We keep our costs down so you get the best product installed for the best value. We don't waste our money on marketing and commissions. We focus on product and process for our clients. With us, you won't get a slick sales pitch. You will get honest and direct information. No pressure to purchase. You let us know when we can do the job for you. A local Texas solar installation done right.",
    paragraph2:
      "Texas power outages happen. They could be happening more with more electrical consumption happening. Growth in population and in businesses is adding to the power needed from the grid. A lot of infrastructure needs to be updated and a lot needs to be added to keep up. Doing solar and batteries on your home is a great way to create grid independence. The solar panels will recharge your batteries when the suns out and batteries will back up your electrical needs that are designated to the batteries. We do a lot of whole home battery solutions and are happy to serve!",
  },
};

export default homeData;
