import React from "react";
import { Helmet } from "react-helmet-async";
import { Grid } from "@mui/material";

import HERO_BANNER_IMG from "../../assets/images/latest/BATTERY.jpg";
import THUMB_IMAGE2 from "../../assets/images/thumb2.jpg";
import THUMB_IMAGE3 from "../../assets/images/latest/WholeHomeBatteryBackUp.jpg";
import THUMB_IMAGE4 from "../../assets/images/latest/BatteryGroundMountSystem.png";
import batteryData from "../../data/battery";

function Battery(props) {
  return (
    <>
      <Helmet>
        <title>
          Batteries: Batteries to back up your home in case of a Texas grid
          outage
        </title>
        <meta name="description" content="Batteries to back up your home in case of a Texas grid outage. Most of the time these batteries are connected to solar which will recharge the batteries from the sun. We can back up whole homes and businesses or just specific electrical loads"/>
        <meta
          name="keywords"
          content="Texas grid outage, Texas grid failure, Texas whole home battery back, Solar batteries, Solar back up, Tesla batteries, Enphase batteries, Off Grid Solar"
        />
        <link rel="canonical" href="https://texassolar.pro/battery" />
      </Helmet>
      <div className="battery-page">
        <section
          className="hero-section secondary"
          style={{ backgroundImage: `url(${HERO_BANNER_IMG})` }}
        >
          <h3>Battery</h3>
        </section>
        <section className="battery-description-section bg-dark">
          <div className="container">
              <p>{batteryData.mainContent.paragraph1}</p>
          </div>
        </section>
        {/* Battery system section */}
        <section className="battery-system-section">
          <div className="container">
            <Grid container spacing={3} className="battery-system-grid">
              <Grid item md={5}>
                <div className="card-blue">
                  <h3>
                    Without a<span className="inline">battery system</span>
                    your solar will not work in an outage.
                  </h3>
                  <p>
                    Selecting the right battery backup depends on your
                    priorities. As professionals, we aim to understand your
                    needs and collaboratively design a system with you. Here are
                    the two overarching battery layouts we offer.
                  </p>
                </div>
              </Grid>
              <Grid item md={7}>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <div className="card img-content-card">
                      <div className="card-left">
                        {/* <h4 className="outline-text">01</h4> */}
                        <h5>Essential loads system</h5>
                        <p>
                          Our system powers essential needs—like your fridge,
                          freezer, Wi-Fi, lighting, and fans—through a
                          specialized sub-panel. During outages, it
                          automatically shifts to solar and battery backup,
                          ensuring your critical services remain uninterrupted.
                          This streamlined approach offers a cost-effective
                          solution, focusing on essentials and excluding 240V
                          power.
                        </p>
                      </div>
                      <div
                        className="card-right"
                        style={{
                          backgroundImage: `url(${THUMB_IMAGE2})`,
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <div className="card img-content-card">
                      <div className="card-left">
                        {/* <h4 className="outline-text">02</h4> */}
                        <h5>Whole Home Battery Backup</h5>
                        <p>
                          Our whole home battery backup solution encompasses
                          your entire home's needs, taking into account key
                          factors such as the number of AC units, heating type
                          (electric or natural gas), and the configuration of
                          your electrical panel. We also consider whether a load
                          shedding system is necessary to maintain power during
                          an outage, ensuring your home stays powered and
                          protected seamlessly.
                        </p>
                      </div>
                      <div
                        className="card-right"
                        style={{
                          backgroundImage: `url(${THUMB_IMAGE3})`,
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </section>

        {/* Battery dark section */}
        <section className="bg-dark reverse battery-quotes-section">
          <div className="container">
            <div className="thumb">
              <img src={THUMB_IMAGE4} alt="" />
            </div>
            <div className="quotes-block h4">
              “We're passionate about creating whole-home battery systems and
              have a fantastic team ready to deliver these solutions to our
              clients. While there are many options out there, including
              generators, we believe it's important to understand all the
              possibilities to make the best choice for your home's energy
              needs.”
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Battery;
