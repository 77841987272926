/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Parallax, Pagination, Navigation } from "swiper/modules";
// import { DALLAS_IMG, DALLAS_IMG2, FORT_WORTH_IMG, IC_CLOSE, IC_LEFT_ARROW, IC_MAPPIN_BLUE, IC_RIGHT_ARROW, PARKER_IMG, SANGER_IMG, SUNNYVALE_IMG, WAXAHACHIE_IMG, WAXAHACHIE_IMG2, WYLIE_IMG } from "../../assets/images";
import { Chip, Dialog, DialogContent, Grid, IconButton } from "@mui/material";
import WAXAHACHIE_IMG from "../../../assets/images/waxahachie-img.jpg";
import WYLIE_IMG from "../../../assets/images/wylie-img.jpg";
import DALLAS_IMG from "../../../assets/images/dallas-img.jpg";
import WAXAHACHIE_IMG2 from "../../../assets/images/waxahachie-img2.jpg";
import DALLAS_IMG2 from "../../../assets/images/dallas-img2.jpg";
import FORT_WORTH_IMG from "../../../assets/images/fort-worth-img.jpg";
// import SUNNYVALE_IMG from "../../../assets/images/sunnyvale-img.jpg";
import TYLER_IMG from "../../../assets/images/latest/BatteryGroundMountSystem.png";
import PARKER_IMG from "../../../assets/images/parker-img.jpg";
import SANGER_IMG from "../../../assets/images/sanger-img.jpg";
import IC_MAPPIN_BLUE from "../../../assets/images/ic-mappin-blue.svg";
import IC_CLOSE from "../../../assets/images/ic-close.svg";

function ImageGallery() {
  // Set slider index on click
  const [currentSlide, setCurrentSlide] = useState(0);

  // dialog toggle
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleGridItemClick = (slideIndex) => {
    setCurrentSlide(slideIndex);
    handleDialog();
  };

  const imgData = [
    { img: WAXAHACHIE_IMG, name: "Waxahachie" },
    { img: WYLIE_IMG, name: "Wylie" },
    { img: DALLAS_IMG, name: "Dallas" },
    { img: WAXAHACHIE_IMG2, name: "Waxahachie" },
    { img: DALLAS_IMG2, name: "Dallas  " },
    { img: FORT_WORTH_IMG, name: "Fort Worth" },
    { img: TYLER_IMG, name: "Tyler" },
    { img: PARKER_IMG, name: "Parker" },
    { img: SANGER_IMG, name: "Sanger" },
  ];

  return (
    <>
      <Grid container spacing={{ xs: 2, lg: 5 }} className="image-grid">
        {imgData.map((row, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <div
              className="item"
              onClick={() => handleGridItemClick(index)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Space") {
                  // Handle the keyboard event (e.g., trigger the same action as click)
                  handleGridItemClick(index);
                }
              }}
              role="button"
              tabIndex={0}
            >
              <img src={row.img} alt="" className="thumb" />
              <Chip
                icon={<img src={IC_MAPPIN_BLUE} alt="" />}
                label={row.name}
                className="white"
              />
            </div>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleDialog}
        scroll="body"
        className="slider-dialog"
      >
        <DialogContent>
          <IconButton onClick={handleDialog} className="close-btn">
            <img src={IC_CLOSE} alt="" />
          </IconButton>
          <Swiper
            spaceBetween={1}
            slidesPerView={1}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            modules={[Navigation, Pagination, Parallax]}
            pagination={{
              type: "fraction",
            }}
            speed={1000}
            parallax
            initialSlide={currentSlide}
          >
            {imgData.map((row, i) => (
              <SwiperSlide key={i}>
                <div className="item">
                  <Chip
                    icon={<img src={IC_MAPPIN_BLUE} alt="" />}
                    label={row.name}
                    className="white"
                  />
                  <img
                    src={row.img}
                    alt=""
                    className="thumb"
                    data-swiper-parallax="50%"
                    data-swiper-parallax-opacity="0"
                  />
                </div>
              </SwiperSlide>
            ))}
            <div className="swiper-nav">
              <div className="swiper-button-prev">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.21938 11.4694L9.96938 4.71944C10.1101 4.57871 10.301 4.49965 10.5 4.49965C10.699 4.49965 10.8899 4.57871 11.0306 4.71944C11.1714 4.86017 11.2504 5.05105 11.2504 5.25007C11.2504 5.44909 11.1714 5.63996 11.0306 5.7807L5.56031 11.2501L20.25 11.2501C20.4489 11.2501 20.6397 11.3291 20.7803 11.4697C20.921 11.6104 21 11.8012 21 12.0001C21 12.199 20.921 12.3897 20.7803 12.5304C20.6397 12.6711 20.4489 12.7501 20.25 12.7501L5.56031 12.7501L11.0306 18.2194C11.1714 18.3602 11.2504 18.551 11.2504 18.7501C11.2504 18.9491 11.1714 19.14 11.0306 19.2807C10.8899 19.4214 10.699 19.5005 10.5 19.5005C10.301 19.5005 10.1101 19.4214 9.96937 19.2807L3.21938 12.5307C3.14964 12.461 3.09432 12.3783 3.05658 12.2873C3.01884 12.1962 2.99941 12.0986 2.99941 12.0001C2.99941 11.9015 3.01884 11.8039 3.05658 11.7129C3.09432 11.6218 3.14964 11.5391 3.21938 11.4694Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="swiper-button-next">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.3172 10.4425L11.6922 16.0675C11.5749 16.1848 11.4159 16.2507 11.25 16.2507C11.0841 16.2507 10.9251 16.1848 10.8078 16.0675C10.6905 15.9503 10.6247 15.7912 10.6247 15.6253C10.6247 15.4595 10.6905 15.3004 10.8078 15.1832L15.3664 10.6253H3.125C2.95924 10.6253 2.80027 10.5595 2.68306 10.4423C2.56585 10.3251 2.5 10.1661 2.5 10.0003C2.5 9.83459 2.56585 9.67562 2.68306 9.55841C2.80027 9.4412 2.95924 9.37535 3.125 9.37535H15.3664L10.8078 4.81753C10.6905 4.70026 10.6247 4.5412 10.6247 4.37535C10.6247 4.2095 10.6905 4.05044 10.8078 3.93316C10.9251 3.81588 11.0841 3.75 11.25 3.75C11.4159 3.75 11.5749 3.81588 11.6922 3.93316L17.3172 9.55816C17.3753 9.61621 17.4214 9.68514 17.4529 9.76101C17.4843 9.83688 17.5005 9.91821 17.5005 10.0003C17.5005 10.0825 17.4843 10.1638 17.4529 10.2397C17.4214 10.3156 17.3753 10.3845 17.3172 10.4425Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </Swiper>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ImageGallery;
