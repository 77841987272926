/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import Header from "../components/ui/Header/header";
import Footer from "../components/ui/Footer/footer";

function Layout(props) {

  const { children } = props;

  return (
    <div className="wrapper">
      <Header />
      <main className="main-content">   
        {children}     
        </main>
        <Footer />
    </div>
  );
}
Layout.propTypes = {
  children: PropTypes.any,
};
export default Layout;
